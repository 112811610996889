export enum Feature {
  /** Enable payment of travel extras in points */
  TRAVEL_EXTRA_POINTS = 'TRAVEL_EXTRA_POINTS',
  /** Enable Swish payments */
  SWISH_ENABLED = 'SWISH_ENABLED',
  /** Enable UnionPay payments */
  UNIONPAY_ENABLED = 'UNIONPAY_ENABLED',
  /** Limit points payments such that the checkout has a maximum of three payments */
  MAX_THREE_PAYMENTS = 'MAX_THREE_PAYMENTS',
  /** Control whether to show Trustly for mobile UK and SE payments **/
  DEBUG_MOBILE_TRUSTLY = 'DEBUG_MOBILE_TRUSTLY',
  /** Process pending payment as a successful payment */
  PENDING_AS_SUCCESS = 'PENDING_AS_SUCCESS',
  /** Datadog RUM */
  DATADOG_RUM = 'DATADOG_RUM',
  /** Enable all points payments */
  POINTS_ENABLED = 'POINTS_ENABLED',
  /** Use more generic gift card content that doesn't specify what to use it for */
  GIFT_CARDS_FOR_ANCILLARIES = 'GIFT_CARDS_FOR_ANCILLARIES',
  /**
   * Use surcharge rules from checkout instead of what payload provides.
   * Does not support conditionality based on the checkout and is used instead before checkout is created
   * Basically it's just an on/off switch, affecting all the checkouts created in the environment where it's enabled
   * */
  CHECKOUT_SURCHARGES = 'CHECKOUT_SURCHARGES',
}
