import * as Sentry from '@sentry/react';
import { get, has } from 'lodash';
import { cmsBaseUrl, getTermsAndConditionsContents, getTexts, parseLocale, Texts } from './i18n';

const globalDisturbanceBannerUrlKey = 'fragments.globalDisturbanceBanner.url';

type ReplaceParams = Array<string | number>;

let cachedTranslations: Texts;
let useGenericGiftCard: boolean;

const replaceMsgFormat = (input: string, args: ReplaceParams = []): string => {
  if (typeof input !== 'string') return input;

  let result = input;
  args.forEach((value, key) => {
    const regex = new RegExp(`\\{${key}\\}`, 'g');
    result = result.replace(regex, String(value));
  });
  return result;
};

// TODO: Just use Generic Gift Card translation once we have gone live with possibility to pay ancillaries with gift cards
export function i18n(key: string, ...args: ReplaceParams): string {
  const genericKey = `${key}Generic`;
  if (useGenericGiftCard && has(cachedTranslations, genericKey)) {
    return replaceMsgFormat(get(cachedTranslations, genericKey) as string, args);
  } else if (!cachedTranslations || !has(cachedTranslations, key)) return `${key}___TRANSLATION_MISSING`;
  return replaceMsgFormat(get(cachedTranslations, key) as string, args);
}

export const i18nSimple = (key: string, fallback: string) => get(cachedTranslations, key, fallback) as string;

export const getGlobalDisturbanceBannerUrl = (): string | undefined => {
  const bannerUrl = get(cachedTranslations, globalDisturbanceBannerUrlKey);
  if (bannerUrl) {
    return `${cmsBaseUrl}${bannerUrl}?view=fin-fragment-json&properties=items`;
  }
  return undefined;
};

export const getFragmentUrl = (
  fragmentName: string,
  properties: string[],
  translations: unknown
): string | undefined => {
  const url = get(translations, `fragments.${fragmentName}.url`);
  if (url) {
    return `${cmsBaseUrl}${url}?view=fin-fragment-json&properties=${properties.join(',')}`;
  }
  return undefined;
};

export const getTermsAndConditionsFromTranslations = async (
  translations = cachedTranslations
): Promise<{ title: string; content: string } | undefined> => {
  try {
    // TODO: Once content team is ready, we can define the terms and conditions by service
    const url = getFragmentUrl('termsAndConditions', ['detailText', 'teaserTitle'], translations);
    if (url) {
      return await getTermsAndConditionsContents(url);
    } else {
      throw new Error(`Failed to load terms and conditions from ${url}`);
    }
  } catch (err) {
    let message = err;
    if (err instanceof Error) {
      message = err.message;
    }
    Sentry.captureException(`Failed to load terms and conditions content, ${message}`);
    throw err;
  }
};

export const loadTranslations = async (locale: string) => {
  cachedTranslations = await fetchTranslations(locale);
};

export const fetchTranslations = async (locale: string) => {
  const [lang, country] = locale.toLowerCase().split('_');
  return getTexts(parseLocale(`${country}-${lang}`));
};

export const setTranslations = (translations: Texts) => {
  cachedTranslations = translations;
};

// TODO: Remove once Gift card for ancillaries has gone live
export const setUseGenericGiftCard = (isGiftCardForAncillaries?: boolean): void => {
  useGenericGiftCard = !!isGiftCardForAncillaries;
};
